import React from 'react';
import styled from "styled-components";
import Footer from "../components/footer";
import Header from "../components/header";
import BackArrow from "../images/backArrow.svg"
import {Link} from "gatsby";
import News from "../components/news";
import Decoration from "../images/decoration.svg";
import "../index.css"
import {Helmet} from "react-helmet";
import Favicon from "../images/favicon.svg";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1110px;
  align-self: center;
  @media screen and (max-width: 1100px) {
    width: 1000px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
  }
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  color: #212939;
  line-height: 22px;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  width: 1110px;
  align-self: center;
  @media screen and (max-width: 1100px) {
    width: 1000px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
  }
`;

const ReturnImages = () => {
    return (
        <>
            <Image src={Decoration} alt="decoration"/>
        </>
    )
}

const Image = styled.img`
  position: absolute;
  left: -90px;
  top: 270px;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

const Image2 = styled.img`
  position: absolute;
  left: 1230px;
  top: 760px;
  z-index: -1;
  transform: matrix(-1, 0, 0, 1, 0, 0)
`;

const NewsPage = () => {
    return (
        <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Новости</title>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://pp-proekt.ru/newsPage"/>
            </Helmet>
            <MainContainer>
                <ReturnImages/>
                <div style={{alignSelf: "center"}}>
                    <Header/>
                </div>
                <RowContainer>
                    <Link style={{textDecoration: "none", display: "flex", flexDirection: "row"}} to="/">
                        <img style={{marginRight: "16px"}} src={BackArrow} alt="backArrow"/>
                        <Text>
                            Назад на главную
                        </Text>
                    </Link>
                </RowContainer>
                <News/>
            </MainContainer>
            <Footer/>
        </div>
    )
}

export default NewsPage;