import React from 'react';
import styled from "styled-components";
import {Link} from "gatsby";
import Plug from "../images/plug.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1110px;
  align-self: center;
  margin-bottom: 197px;
  @media screen and (max-width: 1100px) {
    width: 1000px;
    margin-bottom: 170px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
    margin-bottom: 150px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
    margin-bottom: 130px;
  }
`;

const Subtitle = styled.div`
  font-family: Montserrat;
  font-weight: ${props => props.type === "main" ? "700" : "800"};
  font-size: ${props => props.type === "main" ? "24px" : "30px"};
  color: #4C77FF;
  margin-bottom: ${props => props.type === "main" ? "46px" : "40px"};
  @media screen and (max-width: 1100px) {
    font-size: ${props => props.type === "main" ? "20px" : "26px"};
    margin-bottom: ${props => props.type === "main" ? "40px" : "34px"};
  }
  @media screen and (max-width: 960px) {
    font-size: ${props => props.type === "main" ? "16px" : "22px"};
    margin-bottom: ${props => props.type === "main" ? "32px" : "28px"};
  }
  @media screen and (max-width: 800px) {
    font-size: ${props => props.type === "main" ? "12px" : "18px"};
    margin-bottom: ${props => props.type === "main" ? "27px" : "22px"};
  }
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  color: #212939;
  line-height: 22px;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const TextLink = styled(Link)`
  font-family: Montserrat;
  font-size: 14px;
  color: #212939;
  line-height: 22px;
  margin-top: 5px;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RowContainer2 = styled(RowContainer)`
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: unset;
`;

const NewsCard = styled.div`
  font-family: Montserrat;
  width: 350px;
  font-size: 14px;
  color: #444A56;
  margin-right: ${props => props.index % 3 !== 0 || props.index === 1 || props.index === 2 ? "30px" : "0"};
  margin-bottom: ${props => props.type === "main" ? "0" : "52px"};
  text-align: left;
  position: relative;
  @media screen and (max-width: 1100px) {
    width: 300px;
    font-size: 12px;
  }
  @media screen and (max-width: 960px) {
    width: 250px;
    font-size: 10px;
  }
  @media screen and (max-width: 800px) {
    width: 200px;
    font-size: 8px;
  }
`;

const Date = styled.div`
  margin-bottom: 18px;
`;

const PlugText = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #212939;
`;

const News = ({type, news}) => {
    return (
        <Container>
            <RowContainer2>
                <Subtitle type={type}>
                    Новости
                </Subtitle>
                {type === "main" ? <TextLink to="/news">
                        Подробнее
                    </TextLink>
                    : ""}
            </RowContainer2>
            <img src={Plug} alt="Plug"/>
            <PlugText>
                Раздел находится в разработке, следите за обновлениями!
            </PlugText>
            {/*<RowContainer>
                {news.map((item, index) => (
                    <NewsCard type={type} index={index + 1}>
                        <Date>
                            {item.published_at}
                        </Date>
                        <Text>
                            {item.title}<br/>
                            <TextLink to={'/news/' + (index + 1)}>
                                Читать больше
                            </TextLink>
                        </Text>
                    </NewsCard>
                ))}
            </RowContainer>*/}
        </Container>
    )
}

export default News;